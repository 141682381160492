import * as React from "react";
import Card from "@mui/material/Card";
import {
  Avatar,
  Box,
  Chip,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import { backgroundColorList, colorsBorderGraph } from "../helper/graphProps";
import ElectricBoltOutlinedIcon from "@mui/icons-material/ElectricBoltOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Button, Tooltip as TooltipMui } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CompanyCard = (props) => {
  const { companyName, data } = props;

  const [stateType, setStateType] = React.useState("line");
  const [showGraph, setShowGraph] = React.useState(false);
  const [historicPrice, setHistoricPrice] = React.useState(
    data?.promocional ? data?.promoPriceHistory : data?.normalPriceHistory
  );
  const mobileMode = useMediaQuery("(max-width:900px)");
  const laptopMode = useMediaQuery(
    "((max-width: 1300px) and (min-width:801px))"
  );
  const largeMode = useMediaQuery("(min-width: 1301px)");

  const handleChangeState = (event) => {
    setStateType(event.target.value);
  };

  const [priceGraph, setPriceGraph] = React.useState({
    labels: historicPrice?.map((item) => item.submittedDate),
    datasets: [
      {
        label: historicPrice?.map((item) =>
          data?.promocional ? item.startDate : item.submittedDate
        ),
        data: historicPrice?.map((item) => item.price),
        // backgroundColor: ["rgba(75,192,192,1)"],
        borderColor: "red",
        backgroundColor:
          backgroundColorList[
            Math.floor(Math.random() * backgroundColorList.length)
          ],
        // borderColor: "red",
        borderWidth: 2,
      },
    ],
  });

  React.useEffect(() => {
    reRenderGraph();
    reRenderGraph2();
  }, [data]);

  const reRenderGraph = () => {
    if (stateType === "line") {
      setPriceGraph({
        labels: historicPrice?.map((item) =>
          data?.promocional ? item.startDate : item.submittedDate
        ),
        datasets: [
          {
            label: historicPrice?.map((item) =>
              data?.promocional ? item.startDate : item.submittedDatee
            ),
            data: historicPrice?.map((item) => item.price),
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          },
        ],
      });
    } else {
      setPriceGraph({
        labels: historicPrice?.map((item) =>
          data?.promocional ? item.startDate : item.submittedDate
        ),
        datasets: [
          {
            // label: data.priceHistory.map((item) => item.submittedDate),
            data: historicPrice?.map((item) => item.price),
            // backgroundColor: ["rgba(75,192,192,1)"],
            backgroundColor: backgroundColorList,
            borderColor: colorsBorderGraph,
            borderWidth: 1,
          },
        ],
      });
    }
  };

  // const [userData, setUserData] = React.useState({
  //   labels: ["test", "test1", "test 67", "76r7w"],
  //   datasets: [
  //     {
  //       label: "Indikatori 1",
  //       data: [34, 45, 56, 65],
  //       backgroundColor: ["rgba(75,192,192,1)"],
  //       borderColor: "red",
  //       borderWidth: 2,
  //     },
  //   ],
  // });

  // React.useEffect(() => {
  //   reRenderGraph();
  // }, []);

  const reRenderGraph2 = () => {
    const objectConst = [];
    const dataLabel = [];

    for (var i = 0; i < historicPrice?.length; i++) {
      dataLabel.push(
        String(
          data?.promocional
            ? historicPrice[i]?.startDate
            : historicPrice[i]?.submittedDate
        )
      );
      objectConst.push({
        label: String(
          data?.promocional
            ? historicPrice[i]?.startDate
            : historicPrice[i]?.submittedDate
        ),
        data: String(historicPrice[i]?.price),
        backgroundColor: backgroundColorList[i],
        borderColor: colorsBorderGraph[i],
        borderWidth: 4,
      });
    }
    const test123 = {
      labels: dataLabel,
      datasets: objectConst,
    };
    // setUserData({
    //   labels: dataLabel,
    //   datasets: objectConst,
    // });
  };

  return (
    <Card
      sx={{
        marginBottom: "12px",
        borderRadius: "3px",
        transition: ".3s",
        boxShadow: "0 1px 6px -1px rgba(0,0,0,.18)",
        padding: mobileMode ? "20px 10px 10px 10px" : "40px 20px 30px",
      }}
    >
      <Box
        sx={{
          flexWrap: "wrap",
          display: "flex",
          paddingLeft: mobileMode ? "10px" : "30px",
        }}
      >
        <Box sx={{ paddingRight: mobileMode ? "0px" : "30px", width: "100%" }}>
          <Box sx={{ display: "flex", width: "100%" }}>
            {largeMode && (
              <Box
                sx={{
                  height: "200px",
                  border: "1px solid #e3e3e3",
                  borderRadius: "16px",
                  width: "200px",
                  marginRight: "20px",
                  boxShadow: "0 1px 8px -3px rgba(0,0,0,.3)",
                }}
              >
                <ElectricBoltOutlinedIcon
                  sx={{ color: "#008fc6", width: "200px", height: "200px" }}
                />
              </Box>
            )}
            <Box
              sx={{
                boxShadow: "0 1px 8px -3px rgba(0,0,0,.3)",
                width: "100%",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                  background: "#008fc6",
                  borderRadius: "4px",
                  padding: "0px 8px",
                  height: "40px",
                  alignItems: "center",
                }}
              >
                <Typography color="white" variant="h5">
                  Kompania:
                </Typography>{" "}
                {companyName.length > 32 ? (
                  <TooltipMui title={companyName}>
                    <Typography noWrap color="white" variant="h5">
                      {companyName}
                    </Typography>
                  </TooltipMui>
                ) : (
                  <Typography color="white" variant="h5">
                    {companyName}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  marginBottom: "10px",
                  background: "#f4fcff",
                  borderRadius: "4px",
                  padding: "0px 5px",
                }}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Çmimi Aktual: </Typography>{" "}
                  <Typography>
                    {data?.currentPrice?.toLocaleString("en-US")}
                  </Typography>
                </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Qyteti: </Typography>{" "}
                  <Typography>{data?.company?.city}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Website: </Typography>{" "}
                  <Typography>{data?.company?.website}</Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Adresa: </Typography>{" "}
                  <Typography>{data?.company?.address}</Typography>
                </Box>
              </Box>
              <Box sx={{ fontWeight: "600" }} variant="label">
                Çmimi Aktual: {data?.currentPrice?.toLocaleString("en-US")}
                {data?.currenctCurrency != null
                  ? " / " + data?.currenctCurrency
                  : ""}
              </Box>
              {data?.currentFashaMin !== null && (
                <Box sx={{ fontWeight: "600" }} variant="label">
                  Fasha:{" "}
                  {data?.currentFashaMin != null
                    ? data?.currentFashaMin + "-" + data?.currentFashaMax
                    : ""}
                  {data?.currentFashaMinUnit != null
                    ? " / " + data?.currentFashaMinUnit
                    : ""}
                </Box>
              )}
              {data?.currenctZona != null && (
                <Box sx={{ fontWeight: "600" }} variant="label">
                  Zona: {data?.currenctZona != null ? data?.currenctZona : ""}
                </Box>
              )}
              {data?.productRange && (
                <Box
                  sx={{ fontWeight: "600", color: "#545454" }}
                  variant="label"
                >
                  Niveli i Tensionit: {data?.productRange?.name}
                </Box>
              )}
              {data?.promocional && (
                <>
                  <Box
                    sx={{
                      fontWeight: "600",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    variant="label"
                  >
                    <Chip
                      icon={<CardGiftcardIcon />}
                      label="Çmim Promocional "
                      color="primary"
                    />
                  </Box>
                  <Box
                    sx={{
                      fontWeight: "600",
                      color: "#008fc6",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    variant="label"
                  >
                    {data?.promocionalStartDate}
                    <span style={{ color: "black", margin: "0px 4px" }}>
                      {" "}
                      deri në{" "}
                    </span>
                    {data?.promocionalEndDate}
                  </Box>
                </>
              )}
              {data?.company?.info3 && (
                <span style={{ color: "#008fc6" }}>
                  <span style={{ fontWeight: "500" }}>Kontakte:</span>
                  <span style={{ color: "rgb(88 89 0)" }}>
                    {data?.company?.info3}
                  </span>
                </span>
              )}
              {data?.showDisclaimer && (
                <span
                  style={{
                    fontWeight: "600",
                    color: "#f33b3b",
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Çmimi mund të jete subjekt ndryshimi për konsumatorë që kanë
                  karakteristika specifike konsumi
                </span>
              )}
              <span
                style={{
                  fontWeight: "600",
                  color: "#f33b3b",
                  display: "flex",
                  justifyContent: "flex-end",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                {data?.comment ? data?.comment : ""}
              </span>
            </Box>
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              sx={{ marginTop: "5px" }}
              variant="outlined"
              endIcon={
                showGraph ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
              }
              onClick={() => setShowGraph(!showGraph)}
            >
              {showGraph ? "Mbyll Grafikun" : "Shiko Grafikun Historik"}
            </Button>
          </Box>
          {showGraph && (
            <Box>
              <Box sx={{ minWidth: 120, marginTop: "5px" }}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={stateType}
                    onChange={handleChangeState}
                  >
                    <MenuItem value={"line"}>Vije</MenuItem>
                    <MenuItem value={"bar"}>Bar</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minHeight: "400px" }}>
                {stateType === "line" ? (
                  <LineChart
                    chartData={priceGraph}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                ) : (
                  <BarChart
                    id={stateType}
                    chartData={priceGraph}
                    options={{
                      hoverOffset: 8,
                      layout: {
                        padding: {
                          left: 50,
                          right: 0,
                          top: 0,
                          bottom: 0,
                        },
                      },
                      plugins: {
                        tooltip: {
                          callbacks: {
                            labelPointStyle: function (context) {
                              return {
                                pointStyle: "triangle",
                                rotation: 0,
                              };
                            },
                          },
                        },
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default CompanyCard;
